@header-height: 63px;
@header-height-mobile: 49px;
@video-content-offset: 40px;
@footer-height: 65px;
@footer-height-smaller: 47px;
@video-title-height-mobile: 23px;

@keyframes Pulsate {
  0% {transform: scale(0.1, 0.1); opacity: 0;}
  50% {opacity: 1;}
  100% {transform: scale(1.2, 1.2); opacity: 0;}
}

.wrapper {
  height: 100vh;
  height: 100dvh;
  flex-direction: column;
  display: flex;

  .name-wrapper {
    flex: 1;
    overflow: hidden;
    }

}

div.headerunder {
  border-top: none !important;
}

#dl_content {
  margin-top: @header-height;
  padding-top: 0px;
  font-size: 13px;
  flex: 1;

  .document {
    // height: 100%;
    
    // #dLinkDocument-container {
    //   height: 100%;
    // }
  }


  h1 {
    font-size: 24px;
    text-align: center;
    line-height: 32px;
    margin: 0;
    padding: 25px 0 10px 0;

    span {
      font-weight: normal;
      font-size: 21px;
    }
  }

  .thumb {
    margin: 0 auto;
  }

  .button-area {
    padding: 20px;
    padding-bottom: 0;
    text-align: center;
    min-width: 300px;
    display: inline-block;
    margin: 0 auto;

    .modernbut.button {
      box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
      margin: 10px auto;
      height: 44px !important;
      line-height: 44px !important;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: 10px;

      &.downloadButton {
        @mixin retina "/ZCDNURL/img/icons/publink/download-button.png";
      }

      &.saveButton {
        @mixin retina "/ZCDNURL/img/icons/publink/save-button.png";
      }

      &.lightblue {
        border: solid 1px #bcecf1 !important;
        background-color: #f4fcfd;
        color: #17bed0 !important;
      }
    }

    div {
      min-width: 130px;
    }
  }

  html.mobile & {
    margin-top: @header-height-mobile;
  }
}

#dl_content {

  .name-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    flex: 1;
  }

  .title {
    width: 100%;
    font-size: 16px;
    text-align: center;

    .bookmark {
      display: flex;
      align-items: center;
      margin-left: 15px;
  
      img {
        width: 21px;
        height: 20px;
        cursor: pointer;
      }
  
      img.active {
        display: inline-block;
      }
  
      img.inactive {
        display: none;
      }
    }

    .wrap {
      display: inline-block;

      .filename {
        float: left;
        max-width: 900px;
      }
      .size {
        float: left;
        color: #999999;
      }
    }
  }
}

.mobile {
  #dl_content {

    .title {
      font-size: 16px;
      .wrap {
        .filename {
          /*max-width: 300px;*/
        }
      }
    }
  }
}

/*@mixin retina '/ZCDNURL/img/mobile/my-fixed-logo.png';*/

.dlink {
  .branding-header {
    text-align: center;
    // max-width: 1180px;
    margin: 0 auto;
    padding: 36px 15px;
    position: relative;
    display: table;
    width: 100%;

    .aligned-container {
      display: table-cell;
      vertical-align: middle;

      .title-image {
        box-shadow: 0px 4px 7px 0 rgba(0, 0, 0, 0.03),
          0 0 1px 0 rgba(0, 0, 0, 0.2);
        z-index: 2;
        position: relative;
        background-color: #fff;
      }

      .texts {
        display: inline-block;
        width: 100%;
        text-align: center;

        .headline {
          font-size: 19px;
          font-weight: bold;
          word-break: break-word;
          z-index: 2;
          position: relative;
          max-width: 1180px;
          margin: 0 auto;
        }

        .description {
          font-size: 17px;
          font-weight: normal;
          word-break: break-word;
          z-index: 2;
          position: relative;
          max-width: 1180px;
          margin: 0 auto;
        }
      }
    }

    .gradient-bg {
      position: absolute;
      display: none;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 50%;
      z-index: 1;
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0)
      );
    }
  }

  #branding {
    .name-wrapper {
      justify-content: left;
    }
  }

  .header-wrap {
    /*
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    height: initial;
    */
    border-top: 1px solid #e4e4e4;
    box-shadow: none;
    box-sizing: border-box;

    .name-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      flex: 1;
    }

    .filename {
      font-weight: 500;
      max-width: 590px;
    }

    .filename.basic {
      clear: both;
      height: 60px;
      line-height: 60px;
      color: #000;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      padding-left: 15px;

      /* bellow this line, renders title to center */
      // position: absolute;
      // padding-left: 0;
      // top: 0;
      // left: 270px;
      // right: 420px;
      text-align: right;
      z-index: 100;
    }

    .filename.branding {
      height: 60px;
      line-height: 60px;
      color: #000;
      font-family: "Roboto", sans-serif;
      font-size: 15px;
      font-weight: bold;
      max-width: 720px;
    }

    .bookmark {
      display: none;
      align-items: center;
      margin-left: 15px;
      min-width: 34px;

      img {
        width: 21px;
        height: 20px;
        cursor: pointer;
      }

      img.active {
        display: inline-block;
      }

      img.inactive {
        display: none;
      }
    }

    .header {
      position: relative;
      display: flex;
      justify-content: space-between;

      .logo-place {
        clear: both;
      }

      .logo {
        border: none;
      }

      .notifications {
        clear: both;
      }
      .user-holder {
        clear: both;

        .user-header-wrapper {
          border: none;
        }

        .signin-header-button {
          height: 36px;
          min-width: 109px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .links {
        clear: both;
      }

      .buttons {
        clear: both;

        a {
          text-decoration: none;
          vertical-align: top;
          display: inline-block;
          cursor: pointer;
          height: 59px;
          width: 59px;

          &.share {
            width: 21px;
            height: 28px;
            margin: 15px 20px 0 5px;
            background: url("/ZCDNURL/img/icons/publink/share.png") no-repeat
              center center;

            &:hover,
            &.opn {
              background: url("/ZCDNURL/img/icons/publink/share-active.png")
                no-repeat center center;
            }
          }
        }
      }
      .name-wrapper {
        flex: 1;
      }
      .header-links {
        margin-right: 0;
        justify-content: flex-end;
        display: flex;
        align-items: center;

        .services-button {
          a {
            display: flex;
            height: 48px;
          }
        }

        a {
          display: inline-block;
          font-family: "Roboto", sans-serif;
          line-height: 34px;
          text-align: center;
          text-decoration: none;
          margin: 0 0 0 6px;
          font-size: 13px;
          font-weight: 500;
          min-width: 110px;
          height: 36px;
          border-radius: 3.1px;
          box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);

          &:last-child {
            /*margin-right: 20px;*/
          }
        }
      }

      .header-promos-container {
        position: relative;
        z-index: 101;

        .ribbon-wrapper {
          float: right;
          margin: 0 20px;
          height: 60px;

          img {
            background-position: center center;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}

.mobile {
  .cover-photo {
    display: none;
    height: 180px;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  #branding {
    .name-wrapper {
      justify-content: left;
    }
  }

  .branding-header {
    padding: 30px 10px;
    max-height: none;
    box-sizing: border-box;
    min-height: 180px;

    .title-image {
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2), 0px 5px 8px 0 rgba(0, 0, 0, 0.1) !important;
    }

    .headline {
      font-weight: bold;
      font-size: 20px;
      word-break: break-word;
    }

    .description {
      font-size: 17px;
      word-break: break-word;
    }
  }

  .header-wrap {
    .name-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      flex: 1;
    }

    .filename.branding {
      height: 49px;
      line-height: 49px;
      text-align: right;
    }

    .bookmark {
      display: none;
      align-items: center;

      img {
        width: 21px;
        height: 20px;
        cursor: pointer;
      }

      img.active {
        display: inline-block;
      }

      img.inactive {
        display: none;
      }
    }
  }
}

footer {
  text-align: center;
  padding-bottom: 20px;

  .footer-title {
    font-size: 13px;
    color: #8e8e95;
    padding: 30px 0 0 0;

    a {
      color: #17bed0;
      text-decoration: none;
    }
  }

  .social-buttons {
    .icon {
      height: 22px;
      width: 22px;
      display: inline-block;
      margin: 0 10px;
      // background-repeat: no-repeat;
      // background-size: cover;
      // background-position: center;
    }

    .fb {
      background: url("/ZCDNURL/img/DL-socialmedia/fb.png") no-repeat;
    }

    .tw {
      background: url("/ZCDNURL/img/DL-socialmedia/tw.png") no-repeat;
    }

    .gp {
      background: url("/ZCDNURL/img/DL-socialmedia/g.png") no-repeat;
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .fb {
        background: url("/ZCDNURL/img/DL-socialmedia/fb@2x.png") no-repeat;
        background-size: 22px;
      }

      .tw {
        background: url("/ZCDNURL/img/DL-socialmedia/tw@2x.png") no-repeat;
        background-size: 22px;
      }

      .gp {
        background: url("/ZCDNURL/img/DL-socialmedia/g@2x.png") no-repeat;
        background-size: 22px;
      }
    }
  }
}
.mobile {
  footer {
    padding-bottom: 20px;
    @media (max-height: 350px) {
      padding-bottom: 8px;
    }
  }
}

div.footer-title.mobile {
  padding: 30px 0 0 0;

  @media (max-height: 350px) {
    padding: 8px 0 0 0;
  }
}

.image {
  padding-bottom: 15px;

  .thumb {
    &.responsive-thumb {
      width: auto!important;
      max-width: 100%!important;
      height: auto!important;
      text-align: center;
      img {
        width: auto!important;
        max-width: 100%!important;
        height: auto!important;
      }
    }
    display: none;
  }

  .loading {
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 auto;

    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
  }

  &.loaded {
    .thumb {
      display: block;
    }

    .loading {
      display: none;
    }
  }
}

html.document-preview {
  overflow-y: auto;

  .header {
    .logo {
      border-right: 1px solid #1d1e1e;
    }
  }

  // #dl_content {
  // }
}

.mobile {
  .header-wrap {
    height: initial;
    box-shadow: none;

    .header {
      height: @header-height-mobile;
      padding-left: 10px;
      padding-right: 0;
      box-sizing: border-box;
      display: flex;
      margin: 0;
      justify-content: space-between;
      min-width: initial;
      width: initial;

      /*
      z-index: 101;
      position: fixed;
      width: 100%;
      top: 0;

      */

      .logo {
        width: initial;
        height: 49px;
        padding: 0;
        border: none;

        .pcloud-logo {
          margin-top: 12px;
          margin-left: 12px;
          width: 104px;
          height: 25px;
          background-size: 104px 25px;
          background-repeat: no-repeat;
          @mixin retina "/ZCDNURL/img/mobile/my-fixed-logo.png";
        }
      }
      .dlink.logo-place {
        width: fit-content;
        height: 49px;

        a {
          height: 49px;
        }
      }

      .buttons {
        line-height: 49px;
        float: right;
        a {
          width: 49px !important;
          height: 49px !important;
          display: inline-block;
          background-repeat: no-repeat;
          background-position: center center;

          &.share {
            margin: 0;
            background-size: 16px 18px;
            @mixin retina "/ZCDNURL/img/mobile/share.png";
          }

          &.download {
            background-size: 20px 18px;
            @mixin retina "/ZCDNURL/img/mobile/download.png";
          }

          &.upload {
            background-size: 20px 18px;
            @mixin retina "/ZCDNURL/img/mobile/upload.png";
          }

          &.mob-close {
            background-size: 18px 18px;
            @mixin retina "/ZCDNURL/img/mobile/close.png";
          }
        }
      }
    }
  }

  #dl_content {
    h1 {
      font-size: 18px;
      font-weight: normal;

      span {
        font-size: 18px;
      }
    }
  }

  .mob-dialog {
    position: fixed;
    top: 0px;
    z-index: 101;
    background-color: #fff;

    backface-visibility: hidden;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: translateY(150%);
    transform: translateY(150%);

    .header {
      height: @header-height-mobile;
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      margin: 0;
      justify-content: space-between;
      min-width: initial;
      width: initial;
    }
  }

  .mob-dialog.show {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  
    .bookmark {
      display: none;
    }

    .buttons {
      line-height: 49px;
      float: right;

      a {
        width: 18px !important;
        height: 18px !important;
        display: inline-block;
        padding-left: 10px;
      }

      .mob-close {
        margin: 0;
        width: 18px;
        height: 18px;
        background-size: 18px 18px;
        background-position: center;
        background-repeat: no-repeat;
        @mixin retina "/ZCDNURL/img/mobile/close.png";
      }
    }
  }

  .mob-dialog input {
    width: 300px;
  }
}

.sheet-icon {
  &.icon-copy {
    @mixin retina "/ZCDNURL/img/icons/publink/copy.png";
  }
  &.icon-fb {
    @mixin retina "/ZCDNURL/img/icons/publink/fb.png";
  }
  &.icon-tw {
    @mixin retina "/ZCDNURL/img/icons/publink/tw.png";
  }
  &.icon-share {
    @mixin retina "/ZCDNURL/img/icons/publink/icon-share.png";
  }

  &.sheet-icon-download {
    &.icon-dwl {
      @mixin retina "/ZCDNURL/img/icons/publink/download2.png";
    }
    &.icon-save {
      @mixin retina "/ZCDNURL/img/icons/publink/save.png";
    }
  }

  &.sheet-icon-share,
  &.sheet-icon-download {
    width: 32px !important;
    height: 32px !important;
    background-size: 32px 32px !important;
    margin: 0px !important;
    align-self: center;
    flex-shrink: 0;
  }
}

/* over traffic */

.overtraffic {
  width: 650px;
  margin: 40px auto 0px auto;
  border-radius: 5px;
  padding: 5px;

  .over-logo {
    float: left;
    width: 128px;
    margin-right: 20px;
  }

  .over-explain {
    line-height: 24px;
    text-align: center;
    margin: 10px 0 10px 0;
  }

  .over-actioncall {
    border-top: 1px solid #e9e9e9;
    padding-top: 20px;
    padding-bottom: 35px;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 150px;
  }

  .button-area {
    padding: 0 !important;
    text-align: center;
    min-width: 300px;
    display: inline-block;
    margin: 0 auto;
    .button {
      min-width: 180px;
      box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
      margin: 10px auto;
      height: 44px !important;
      line-height: 44px !important;

      &.lightblue {
        border: solid 1px #bcecf1;
        background-color: #f4fcfd;
        color: #17bed0;
      }
    }
  }

  a {
    color: #1bc7cf;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.mobile {
  .overtraffic {
    width: initial;
    margin: 40px 20px 0 20px;
    padding: 0;

    .over-wrap {
      margin: 0 20px;

      .over-logo {
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
      }

      .over-explain {
        width: auto;
      }

      .over-actioncall {
        text-align: justify;
      }
    }
  }
}

.generic {
  /*width: 500px;*/
  margin: 159px auto 0 auto;
  padding-bottom: 60px; /* for resize */
  text-align: center;

  .icon {
    margin: 25px auto 10px auto;
    width: 200px;
    height: 200px;
    border-radius: 110px;
    background: #fafafa;
    text-align: center;
    position: relative;

    .iconwrap {
      margin: 50px 25px;
      display: inline-block;
      float: initial;
    }

    .attention {
      position: absolute;
      bottom: -24px;
      left: 50%;
      margin-left: -24px;
      width: 28px;
      height: 24px;
      @mixin retina "/ZCDNURL/img/mobile/att.png";
      background-color: #ff7a4d;
      background-size: 28px 24px;
      background-position: center center;
      background-repeat: no-repeat;
      width: 48px;
      height: 48px;
      border-radius: 26px;

      border: 2px solid #fff;
    }

    &.thumbready {
      .iconwrap {
        margin-top: 40px;
      }
    }
  }

  .name {
    text-align: center;
    margin: 15px;
    font-size: 18px;
  }

  .not_logged {
    margin-top: 25px;
    line-height: 26px;
    text-align: center;
  }
}

.mobile {
  .generic {
    width: 100%;
    margin: 69px auto 0 auto;
    padding-bottom: 20px;
  }
}

.folder-link {
  width: 1180px;
  margin: auto;
  padding: 0 20px;

  div.cheader {
    margin-bottom: 0;
    // padding-top: 18px;
    min-height: none;
    z-index: 102;
  }

  .mobgridheader b {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .folder-link {
    width: initial;
    /*min-width: 768px;*/
    min-width: 728px;
  }
}

.mobile {
  .document {
    overflow-y: hidden;
    // padding-top: 160px;
  }
  .folder-link {
    padding: 0;
    width: initial;
    min-width: initial;
    // margin: 50px auto 20px auto !important;
  }
}

.document {
  overflow-x: hidden;

  iframe {
    display: block;
    border: none;
    height: 100vh;
    width: 100%;
    overflow-y: auto;
  }

  .loading {
    display: none;
    text-align: center;
  }
}

.document-loading {
  .header {
    .filename {
      display: none;
    }
    .bookmark {
      display: none;
    }
  }

  .document {
    iframe {
      display: none;
    }

    .loading {
      display: block;
      top: 0px;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -10px;
        margin-left: -10px;
      }
    }
  }
}

.audio {
  /*margin: 154px auto 0 auto;*/
  margin: 111px 0 0 0;
  text-align: center;

  .me-cannotplay {
    display: none;
  }

  .iconplayerwrap {
    width: 442px;
    margin: 0 auto 0 auto;
  }

  .name {
    font-weight: bold;
    text-align: center;
    font-size: 16px;
  }

  .icon {
    width: 170px;
    height: 200px;
    margin: 25px auto 0px auto;

    width: 200px;
    height: 200px;
    border-radius: 100px;
    background: rgba(255, 147, 38, 0.1);
    text-align: center;

    img {
      margin: 40px 25px;
    }
  }

  .thumbready {
    .icon {
      img {
        margin: 0;
      }
    }
  }
}

.dropdown-button {
  background: #1bc7cf url("/ZCDNURL/img/share-opt.png") no-repeat right 10px
    center;
  padding: 0 26px 0 10px;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 28px;
  height: 28px;
  color: #fff;
  display: inline-block;
  margin: 15px 15px 0 0;
  cursor: pointer;
  border-radius: 2px;

  &.opn {
    background-color: #16afb8;
  }
}

.controls > .dropdown-button {
  margin: 7px 5px 0 5px;
}
.mobile-bookmark {
  left: 0 !important;
  width: 100%;
}
.mnnew.mn-share {
  z-index: 102;
  ul {
    padding: 0px;

    a {
      padding: 9px 14px !important;
      border-bottom: 1px solid #f3f3f3;
      background: none;

      &.download-menu-item {
        padding: 14px !important;
      }

      &:last-of-type {
        border: none;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &:first-of-type {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      li {
        opacity: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        font-family: "Roboto", sans-serif;
        color: #212121;
        min-width: 322px;

        .info-wrap {
          div {
            line-height: 1.2;
          }

          div.option-title {
            font-weight: 500;
          }

          div.sub-text {
            font-size: 13px;
            color: #999999;
          }
        }

        img {
          margin-right: 10px;
        }

        .download {
          width: 32px;
          height: 32px;
          @mixin retina "/ZCDNURL/img/icons/publink/download2.png";
          background-size: cover;
          background-position: center center;
          display: inline-block;
          margin: 0px;
          vertical-align: middle;
        }

        .save-to-pcloud {
          margin: 0px;
          width: 32px;
          height: 32px;
          @mixin retina "/ZCDNURL/img/icons/publink/save.png";
          background-size: cover;
          background-position: center center;
          display: inline-block;
          vertical-align: middle;
        }

        div.publink-sprite {
          opacity: 1;
        }
      }
    }
  }
}

.sa-player {
  margin-top: 30px;

  .controls {
    width: 170px;
    margin: auto;
    text-align: center;
    margin-bottom: 17px;

    .replay {
      float: left;
      cursor: pointer;
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 13px 16px;
      margin-left: -8px;
      @mixin retina "/ZCDNURL/img/start-over.png";
    }

    .play {
      display: inline-block;
      width: 30px;
      height: 30px;
      background-size: 24px 28px;
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;

      @mixin retina "/ZCDNURL/img/play.png";
      &:hover {
        /*@mixin retina '/ZCDNURL/img/play-active.png';*/
      }
    }
    .play_box {
      display: inline-block;
      width: 26px;
      height: 26px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      margin: 1px 0 3px 0;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;
      .pause_box {
        float: left;
        width: 100%;
        height: 100%;
      }
      .pause_btn {
        width: 8px;
        height: 100%;
        margin: 0 auto;
      }
      .play_btn {
        position: absolute;
        top: 0;
        left: 200px;
      }
      .arrow {
        width: 0;
        height: 0;
        border-right: 0px solid transparent;
        position: relative;
        z-index: 3;
      }
    }

    .repeat {
      float: right;
      cursor: pointer;
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 18px 13px;
      margin-right: -6px;
      @mixin retina "/ZCDNURL/img/repeat.png";
    }
  }

  &.repeat-on {
    .controls .repeat {
      @mixin retina "/ZCDNURL/img/repeat-active.png";
    }
  }

  &.playing {
    .controls {
      .play {
        @mixin retina "/ZCDNURL/img/pause.png";
        &:hover {
          /*@mixin retina '/ZCDNURL/img/pause-song-active.png';*/
        }
      }
    }
  }

  .bars {
    width: 355px;
    margin: 0 auto;
    .progress-bar {
      width: 350px;
      border-radius: 3px;
      height: 4px;
      background: #eee;
      float: left;
      position: relative;
      margin-top: 13px;

      &.dragging {
        cursor: pointer;
      }

      .fill {
        position: absolute;
        background: #333;
        border-radius: 5px;
        height: 100%;
        z-index: 2;
        cursor: pointer;
      }

      .preload {
        position: absolute;
        background: #ddd;
        border-radius: 5px;
        height: 100%;
        z-index: 1;
        cursor: pointer;
      }
    }

    .volume {
      width: 30px;
      float: right;
      margin: -68px 0 0 0;
      position: relative;
      text-align: center;

      .volume-bar {
        display: none;
        position: absolute;
        width: 30px;
        height: 50px;
        bottom: 30px;
        left: 0px;
        cursor: pointer;

        .volume-wrap {
          position: absolute;
          width: 4px;
          height: 50px;
          left: 13px;
          top: 0;
          border-radius: 3px;
          background: #eee;

          .fill {
            position: absolute;
            background: #333;
            border-radius: 5px;
            width: 100%;
            bottom: 0;
            z-index: 2;
          }
        }
      }

      .volume-toggle {
        float: right;
        width: 30px;
        height: 30px;
        cursor: pointer;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: 17px 15px;
        @mixin retina "/ZCDNURL/img/sound-more.png";

        &.min {
          @mixin retina "/ZCDNURL/img/sound-mute.png";
        }
      }

      &:hover {
        .volume-toggle {
          @mixin retina "/ZCDNURL/img/sound-more-active.png";
        }
        .volume-toggle.min {
          @mixin retina "/ZCDNURL/img/sound-mute-hover.png";
        }
      }
    }
  }

  .time_box {
    width: 355px;
    margin: 0 auto;
    .times {
      width: 350px;
      float: left;

      .time,
      .total-time {
        font-size: 13px;
        line-height: 33px;
      }

      .time {
        float: left;
      }

      .total-time {
        float: right;
      }
    }
  }

  .volume-percent {
    width: 30px;
    float: right;
    text-align: center;
    line-height: 33px;
  }
}

.video {
  height: calc(100vh - @header-height - @footer-height);
  height: calc(100dvh - @header-height - @footer-height);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px 0;
  width: 100%;

  .player-wrap {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .player {
      text-align: center;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      align-items: center;

      &.loading {
        &::after {
          content: " ";
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          pointer-events: none;
          z-index: 1;
          width: 76px;
          height: 76px;
          top: 50%;
          left: 50%;
          margin: -38px 0 0 -38px;
          border: 4px solid #20bed6;
          border-radius: 100%;
          animation: Pulsate 1s ease-out;
          animation-delay: 150ms;
          animation-iteration-count: infinite;
          opacity: 0;
        }
      }
    }

    .player-ad {
      display: none;
      width: 300px;
      height: 250px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -125px;
      margin-left: -150px;
      border: 1px solid #6b6b6b;

      cursor: pointer;
    }
  }

  &.embed {
    .player {
      position: fixed;
      width: 100% !important;
      height: 100% !important;
      top: 0;
      left: 0;
      z-index: 200;
    }
  }
}

#dl_content.centralized {
  .video {
    margin-top: 0;
  }
}

#dl_content.basic {
  margin-top: 60px;
}

#dl_content.branding {
  margin-top: 0;
}

.folder-lin.basic {
  margin-top: 84px;
}

.folder-lin.branding {
  margin: 24px auto 20px auto;
}

.mobile {
  .video {
    height: unset;
    min-width: 300px;
    gap: 16px;

    @media (max-height: 350px) {
      padding-top: 8px;
      gap: 8px;
    }

    padding-bottom: 0;

    ._title {
      margin: 0 16px;
      height: 23px;
      line-height: initial;
    }

    .player-wrap {
      height: calc(100vh - @header-height-mobile - @footer-height - @video-title-height-mobile - 16px - 16px);
      height: calc(100dvh - @header-height-mobile - @footer-height - @video-title-height-mobile - 16px - 16px);
      @media (max-height: 350px) {
        height: calc(100vh - @header-height-mobile - @footer-height-smaller - @video-title-height-mobile);
        height: calc(100dvh - @header-height-mobile - @footer-height-smaller - @video-title-height-mobile);
      }
    }

    .player {
      min-height: unset;
    }

    .play-video {
      margin: 0 auto;
      background: #eee;
      position: relative;

      img {
        z-index: 150;
      }

      .play-button {
        position: absolute;
        z-index: 200;
        top: 50%;
        left: 50%;
        width: 80px;
        height: 80px;
        margin-top: -40px;
        margin-left: -40px;
        background-size: 80px 80px;
        @mixin retina "/ZCDNURL/img/mobile/video/play.png";
      }
    }
  }
}

/* imported from old */
.publink-sprite {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: cover;
}

.publink-sprite.fb {
  background-image: url("/ZCDNURL/img/icons/publink/fb.png");
}

.publink-sprite.link {
  background-image: url("/ZCDNURL/img/icons/publink/copy.png");
}

.publink-sprite.tw {
  background-image: url("/ZCDNURL/img/icons/publink/tw.png");
}

@media (min--moz-device-pixel-ratio: 2) and (min-resolution: 192dpi),
  (-o-min-device-pixel-ratio: 2/1) and (min-resolution: 192dpi),
  (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi),
  (min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .publink-sprite.fb {
    background-image: url("/ZCDNURL/img/icons/publink/fb@2x.png");
  }

  .publink-sprite.link {
    background-image: url("/ZCDNURL/img/icons/publink/copy@2x.png");
  }

  .publink-sprite.tw {
    background-image: url("/ZCDNURL/img/icons/publink/tw@2x.png");
  }
}

.publink-sprite.mail,
.publink-sprite.fb,
.publink-sprite.gp,
.publink-sprite.tw,
.publink-sprite.link {
  vertical-align: middle;
  display: inline-block;
}

.mobile-ad {
  width: 90%;
  min-width: 150px;
  max-width: 288px;

  height: 90%;
  min-height: 420px;
  max-height: 480px;

  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2601;
  background-color: #fff;

  backface-visibility: hidden;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-transform: translateY(150%);
  transform: translateY(150%);

  /* real style */
  border-radius: 17px;
  /* -- */

  &.show {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }

  .ad-close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
    @mixin retina "/ZCDNURL/img/mobile/ads/close.png";
  }

  .ad-top {
    height: 100%;
    border-radius: 17px;
    background-size: 288px 428px;
    background-position: top center;
    background-repeat: no-repeat;
  }

  .ad-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    /*height: 91px;*/
    overflow-y: hidden;
    padding: 20px;
    background: #fff;
    border-bottom-left-radius: 17px;
    border-bottom-right-radius: 17px;

    .ad-title {
      /*font-weight: bold;*/
      font-size: 18px;
      margin-bottom: 8px;
    }

    .ad-text {
    }

    .ad-button {
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      background: #1bcad4;
      border-radius: 5px;
      margin-top: 15px;
    }
  }
}

.mobile-overlay {
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2600;

  backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;

  &.show {
    background: rgba(0, 0, 0, 0.85);
  }
}

.error {
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 80px;

  .error-message {
    padding: 30px;
    text-align: center;
    border: 1px solid #f2512d;
    background: #fff5f2;
  }
}

.bar.transition.error {
  max-width: 760px;
  margin: 0 auto;
  padding-bottom: 0px;
}

.mobile {
  .error {
    width: initial;
    padding: 0 10px 0 10px;
    min-width: 290px;
    padding-bottom: 49px;
  }
}

.crypto-ad {
  color: #000;
  line-height: 40px;
  margin: 0 auto;
  text-align: right;
  font-size: 17px;

  .signup {
    color: #1bcad4;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.mobile {
  .crypto-ad {
    text-align: center;
    color: #1bcad4;
    text-align: center;

    font-size: 13px;
  }
}

.crypto-ad-modal {
  /*
    width: 90%;
    min-width: 150px;
    max-width: 288px;
    */

  width: 290px;

  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2601;
  background-color: #fff;

  margin-left: -125px;

  backface-visibility: hidden;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-transform: translateY(150%);
  transform: translateY(150%);

  /* real style */
  border-radius: 17px;
  /* -- */

  &.show {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }

  .crypto-ad-inner {
    padding: 20px;
  }

  .crypto-shield {
    width: 90px;
    height: 125px;
    background-size: 90px 125px;
    @mixin retina "/ZCDNURL/img/mobile/sa-ads/shield.png";
    margin: 20px auto 20px auto;
  }

  .crypto-text {
    margin: 0 20px;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
  }

  .crypto-button {
    margin: 20px auto 20px auto;
    display: block;
  }

  &.iphone .crypto-button {
    @mixin retina "/ZCDNURL/img/mobile/sa-ads/ios-button.png";
    width: 209px;
    height: 57px;
    background-size: 209px 57px;
  }

  &.android .crypto-button {
    @mixin retina "/ZCDNURL/img/mobile/sa-ads/andro-button.png";
    width: 209px;
    height: 62px;
    background-size: 209px 62px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
    @mixin retina "/ZCDNURL/img/mobile/ads/close.png";
  }
}

.cryoto-ad {
}

.desktop-message {
  position: fixed;
  bottom: 54px;
  left: 50%;
  margin-left: -364px;
  z-index: 102;

  .message-img {
    width: 100%;
    height: 100%;
  }

  .message-close {
    @mixin retina "/ZCDNURL/img/mobile/ads-2016/close-web-banner.png";
    width: 34px;
    height: 34px;
    background-size: 34px 34px;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 200;

    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }
  }

  .adsbygoogle {
    width: 728px;
    height: 90px;
  }
}

.mobile-message {
  width: 100%;
  /*
    min-width: 150px;
    max-width: 288px;
    */

  /*height: 100%;*/
  /*
    min-height: 420px;
    max-height: 480px;
    */

  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2601;

  backface-visibility: hidden;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-transform: translateY(150%);
  transform: translateY(150%);

  /* real style */
  /*border-radius: 17px;*/
  /* -- */

  &.show {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }

  .message-spot {
    text-align: center;
  }

  .message-inner {
    text-align: center;
    background-repeat: no-repeat;
    background-position: top 48px center;

    .message-inner-image {
      width: 300px;
      height: 160px;
      margin: 0 auto;
    }

    .message-inner-text {
      font-size: 20px;
      color: #fff;
      margin: 30px 20px 30px 20px;
    }

    .message-inner-button {
      text-align: center;

      a {
        color: white;
        background: #24bcc5;
        border-radius: 10px;
        display: inline-block;

        height: 40px;
        line-height: 40px;
        padding: 0 10px 0 10px;
        min-width: 215px;
        text-decoration: none;
      }
    }
  }

  .message-close {
    position: absolute;
    top: 0;
    left: 0;
    @mixin retina "/ZCDNURL/img/mobile/ads-2016/close-mobile.png";
    width: 50px;
    height: 50px;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.action-sheet-item {
  .sheet-icon-download {
    background-size: 27px 19px;
    width: 27px;
    height: 19px;
    margin-top: 15px;

    &.original {
      @mixin retina "/ZCDNURL/img/mobile/video/original.png";
    }

    &.normal {
      @mixin retina "/ZCDNURL/img/mobile/video/normal.png";
    }

    &.hd {
      @mixin retina "/ZCDNURL/img/mobile/video/hd.png";
    }
  }

  &.download-mobile-sheet:not(.cancel) {
    .info-wrap {
      text-align: left;
      padding-right: 10px;

      div {
        line-height: 1.2;
      }
      .title {
        float: none;
        margin: 0px;
      }

      .sub-text {
        font-size: 13px;
        color: #999999;
      }
    }

    display: flex;
    justify-content: space-between;
    height: max-content;
    line-height: 32px;
    padding: 14px 20px;
  }

  &.share-mobile-sheet:not(.cancel) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sheet-icon-share {
      margin-right: 16px !important;
    }
  }
}

#ros-728x90-1 {
  width: 728px;
  height: 90px;
}

.mobile {
  #ros-728x90-1 {
    height: 50px;
    width: 320px;

    position: fixed;
    bottom: 10px;
    left: 50%;
    margin-left: -160px;
  }

  .falconAd {
    display: none;
    width: 300px;
    height: 50px;
    border: 1px solid #000;
    margin: 20px auto 0 auto;
  }
}

.nextAd {
  display: none;
  width: 728px;
  height: 90px;
  position: fixed;
  bottom: 54px;
  left: 50%;
  margin-left: -364px;
}

.mobile {
  .nextAd {
    position: static;
  }

  .adsbygoogle {
    /*border: 1px solid #ddd;*/
  }
}

.pcloud-banner {
  box-sizing: border-box;
  width: 300px;
  height: 50px;
  margin: 10px auto 20px auto;
}

.pcloud-banner-block {
  box-sizing: border-box;
  width: 300px;
  height: 250px;
  margin: 0 auto;
}

.adsense-banner {
  box-sizing: border-box;
  width: 300px;
  height: 50px;
  margin: 0 auto 0 auto;
}

.password {
  width: 450px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 50px;

  .password-icon {
    width: 200px;
    height: 228px;
    @mixin retina "/ZCDNURL/img/dlink/link-bottom.png";
    background-size: 200px 228px;
    margin: 0 auto;
  }

  .password-message {
    font-size: 15px;
    margin: 25px 0 25px 0;
  }

  &.wrong-password {
    .password-message {
      color: red;
    }
  }

  .input-wrap {
    background: #fafafa;
    padding: 15px 20px 15px 20px;
    border: 1px solid #f4f4f4;
    width: 266px;
    margin: 0 auto 20px auto;
    text-align: left;

    span {
      line-height: 20px;
      font-size: 12px;
      font-weight: bold;
    }

    input[type="password"] {
      width: 260px;
      height: 32px;
      line-height: 32px;
      padding: 0 0 0 5px;
      border: 1px solid #aaa;
      vertical-align: middle;
    }
  }

  .submit {
    width: 100px;
    vertical-align: middle;
    background: #1bc9d3;
    border: 1px solid #1bc9d3;
    border-radius: 3px;
    color: #fff;
    line-height: 34px;
    height: 34px;
    padding: 0 10px 0 10px;
    cursor: pointer;
    margin: 1px 0 0 5px;
  }
}

.mobile {
  .password {
    width: initial;
    margin: 40px 20px 0 20px;
    padding: 0;

    .input-wrap {
      width: initial;

      input[type="password"] {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
}

.dl-sidebar {
  z-index: 101;
  width: 400px;
  position: fixed;
  right: 0;
  height: 100%;
  top: 61px;
  transform: translateY(0%);
  transition: all 0.5s ease;
}

.dl-sidebar.minimized {
  /*height: initial;
  top: initial;*/
  top: -55px;
  transition: all 0.3s ease;
  transform: translateY(100%);

  & > div {
    /*margin-top: -55px;*/
  }
}

.profile {
  .dl-stats {
    float: right;
    color: #fff;
    cursor: pointer;
    text-decoration: underline;
    display: block;
    margin: 0 15px 0 10px;

    &:hover {
      text-decoration: none;
    }

    width: 21px;
    height: 54px;
    background-size: 21px 20px;
    background-position: center center;
    background-repeat: no-repeat;
    @mixin retina "/ZCDNURL/img/dlink/stats-opened.png";
  }
}

html.sidebar-opened {
  .header-wrap {
    right: 400px;
    width: inherit;
  }

  #dl_content {
    margin-right: 400px;

    .document {
      right: 400px;
    }
  }

  .dl-stats {
    @mixin retina "/ZCDNURL/img/dlink/stats-closed.png";
  }
}

.folders-dropdown {
  padding: 16px;
  max-width: ~"calc(100% - 32px)";
  width: max-content;
  ul {
    a {
      li {
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
      }
    }
  }
}
